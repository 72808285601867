<template>
	<v-list class="list">
		<v-row class="px-5">
			<v-col cols="12" md="6">
				<v-text-field
					label="Pesquisar Usuário"
					required
					hide-details
					v-model="searchUser"
					filled
					clearable
				/>
			</v-col>

			<v-col md="6">
				<v-menu
					ref="menu"
					v-model="menu"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					max-width="290px"
					min-width="290px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="date"
							label="Data de cadasto"
							persistent-hint
							prepend-inner-icon="mdi-calendar"
							filled
							flat
							clearable
							hide-details
							v-bind="attrs"
							v-on="on"
							@click:clear="date = null"
						/>
					</template>
					<v-date-picker
						v-model="date"
						range
						no-title
						@input="menu = false"
					></v-date-picker>
				</v-menu>
			</v-col>
		</v-row>
		<v-list-item v-for="item in data" :key="item.itemId" class="list__item">
			<v-list-item-avatar>
				<v-avatar size="40" color="#1e90ff" class="pr-0">
					<v-img :src="item.avatar" v-if="item.avatar" />
					<span v-else style="color: #FFF">{{ notAvatar(item.employee) }}</span>
				</v-avatar>
			</v-list-item-avatar>

			<v-list-item-content>
				<div class="list__item__content">
					<p>{{ item.title || item.description }}</p>

					<div class="list__item__content--info">
						<span>
							<v-icon class="mr-2" size="20" color="#1e90ff"
								>query_builder</v-icon
							>
							{{ item.hours }}h
						</span>

						<span>
							<v-icon class="mr-2" size="20" color="#1e90ff">person</v-icon>
							{{ item.employee }} - {{ item.id }}
						</span>

						<span>
							<v-icon class="mr-2" size="20" color="#1e90ff">date_range</v-icon>
							{{ formatDate(item.date) }}
						</span>
					</div>
				</div>
			</v-list-item-content>

			<v-list-item-action>
				<v-btn
					icon
					depressed
					v-if="item.employee_id === me.employee_id"
					@click="deleteOption(item.id, item.pendencyId)"
				>
					<v-icon color="#FF5252">delete</v-icon>
				</v-btn>
			</v-list-item-action>
		</v-list-item>

		<v-dialog v-model="dialog" persistent max-width="310">
			<v-card>
				<v-card-title class="headline"
					>Tem certeza que deseja excluir esse item?</v-card-title
				>
				<v-card-text
					>Ao excluir um item, o mesmo não pode ser recuperado, essa ação é
					permanente</v-card-text
				>
				<v-card-actions>
					<v-spacer />
					<v-btn text @click="deleteNo">Não</v-btn>
					<v-btn text color="green darken-1" @click="deleteItem">Sim</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<a
			@click="loadRegisteredHours"
			href="#"
			v-if="page < pages"
			class="list__show-more"
			>Carregar mais</a
		>
	</v-list>
</template>

<script>
import { mapGetters } from 'vuex'
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export default {
	data: () => ({
		itemId: null,
		pendencyId: null,
		dialog: false,
		date: [],
		menu: false,
		searchUser: '',
		qtd: 7,
		page: 1,
		currentRegisteredHours: [],
		othersRegisteredHours: [],
	}),
	props: {
		details: Array,
	},
	created() {
		this.currentRegisteredHours = this.details.filter(
			(registeredHours, i) => i + 1 <= this.qtd
		)
		this.othersRegisteredHours = this.details.filter(
			(registeredHours, i) => i + 1 > this.qtd
		)
	},

	computed: {
		...mapGetters({ me: 'user/getMe' }),

		filterUser() {
			return this.details.filter(user =>
				user.employee.toLowerCase().match(RegExp(this.searchUser.toLowerCase()))
			)
		},

		pages() {
			return Math.ceil(this.details.length / this.qtd)
		},

		data() {
			if (this.searchUser.length > 0) {
				return this.filterUser
			}
			return this.currentRegisteredHours
		},
	},
	methods: {
		formatDate(date) {
			const newDate = date.split('T')[0]
			return format(parseISO(newDate), 'dd/MM/yyyy', { locale: ptBR })
		},
		notAvatar(name) {
			return name.length === 0 ? '' : name.substr(0, 1)
		},
		deleteOption(itemId, pendencyId) {
			this.itemId = itemId
			this.pendencyId = pendencyId
			this.dialog = true
		},
		deleteNo() {
			this.itemId = null
			this.pendencyId = null
			this.dialog = false
		},
		async deleteItem() {
			const { itemId, pendencyId } = this
			try {
				await this.$store.dispatch('pendencies/deleteItem', {
					itemId,
					pendencyId,
				})
				this.dialog = false
			} catch (e) {
				console.log(e)
			}
		},
		loadRegisteredHours(e) {
			e.preventDefault()
			if (this.page < this.pages) {
				const registeredHours = this.currentRegisteredHours
				const othersRegisteredHours = this.othersRegisteredHours

				const newRegisteredHours = othersRegisteredHours.filter(
					(registeredHours, i) => i + 1 < this.qtd
				)
				this.currentRegisteredHours = [
					...registeredHours,
					...newRegisteredHours,
				]
				othersRegisteredHours.splice(0, this.qtd - 1)
				this.othersRegisteredHours = othersRegisteredHours
			}
			this.page = this.page + 1
		},
	},
}
</script>

<style lang="scss" scoped>
.list {
	&__item {
		width: 100%;
		border-bottom: 1px solid #eef0f7;
		padding-top: 8px;
		padding-bottom: 8px;
		&__content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			p {
				font-size: 14px;
				line-height: 24px;
				font-weight: 500;
				color: #26283b;
				margin-bottom: 7px;
				width: 85%;
			}

			&--info {
				display: flex;
				justify-content: space-between;
				width: 85%;

				span {
					font-size: 14px;
					display: flex;
					align-items: center;
				}
			}
		}
	}

	.btn {
		width: 100px;
		height: 30px;
		border-radius: 5px;
		background: rgba(255, 82, 82, 0.1);
		color: #ff5252;
		font-size: 14px;
		font-weight: 500;
		margin-right: 30px;
	}
	&__show-more {
		color: #1e90ff;
		font-size: 14px;
		line-height: 20px;
		text-decoration: none;
		padding: 15px 0;
		display: flex;
		justify-content: center;
	}
}
</style>
