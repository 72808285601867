<template>
	<v-navigation-drawer
		v-model="dialog"
		absolute
		app
		right
		light
		temporary
		width="544px"
		@input="watchDrawer"
	>
		<v-app-bar flat color="primary" height="75" absolute>
			<div class="header-drawer d-flex justify-space-between align-center">
				<span>Detalhes da pendência: {{ id }}</span>

				<v-btn color="#fff" fab icon @click="setMask" class="button">
					<v-icon>close</v-icon>
				</v-btn>
			</div>

			<template v-slot:extension>
				<v-tabs fixed-tabs v-model="tab" background-color="#fff">
					<v-tab>
						Detalhes da pendência
					</v-tab>
					<v-tab>
						Horas cadastradas
					</v-tab>
				</v-tabs>
			</template>
		</v-app-bar>

		<v-tabs-items v-model="tab" style="margin-top: 120px;">
			<v-tab-item>
				<FormPendency :pendency="details" edit :id="id" />
			</v-tab-item>
			<v-tab-item>
				<ListHours :details="details.items" />
			</v-tab-item>
		</v-tabs-items>
	</v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import FormPendency from '@/components/unique/Dashboard/FormPendency'
import ListHours from '@/components/unique/Dashboard/ListHours'
import overlayDrawer from '@/mixins/overlayDrawer'
export default {
	data() {
		return {
			pendency: {},
			id: null,
			tab: null,
		}
	},
	components: {
		FormPendency,
		ListHours,
	},
	computed: {
		...mapGetters({ details: 'pendencies/details' }),
	},
	created() {
		this.id = this.$route.params.id
	},
	mixins: [overlayDrawer],
	async mounted() {
		await this.$store.dispatch('pendencies/detailsPendency', this.id)
	},
}
</script>

<style lang="scss" scoped>
.header-drawer {
	width: 100%;
	span {
		font-weight: 500;
		font-size: 20px;
		line-height: 32px;
		color: #fff;
	}
}

.buttons {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 20px;
}
</style>
